






























































import { Component, Ref, Vue } from 'vue-property-decorator'
import { User, USER_STATES } from '@/model/User'
import UserDetails from '../../components/core/user/UserDetails.vue'
import UserFormCard from '../../components/core/user/UserFormCard.vue'
import CardProject from '../../components/core/project/CardProject.vue'
import { ROLES } from '@/model/Role'
import { Project } from '@/model/Project'

@Component({
  components: { UserDetails, UserFormCard, CardProject },
})
export default class ViewUser extends Vue {
  @Ref('userDetails') readonly userDetailsEl!: any

  async created() {
    let id = +this.$route.params.id

    if (!id) {
      id = this.$store.getters.loggedUser.id
    }

    this.$store.dispatch('getUser', id).then(json => {
      if (json.success) {
        this.$store.dispatch('getProjects', { userIdExt: id })
      } else {
        return this.$router.back()
      }
    })
  }

  get user(): User {
    return this.$store.getters.user
  }

  get projects(): Project[] {
    return this.$store.getters.projects
  }

  get isEnabled() {
    return this.user.state === USER_STATES.ENABLED
  }

  get canDisable(): boolean {
    return (
      this.$store.getters.loggedUser.role === ROLES.ADMIN &&
      this.user.role === ROLES.USER
    )
  }

  get canDelete(): boolean {
    return (
      this.$store.getters.loggedUser.role === ROLES.SUPERADMIN ||
      this.$store.getters.loggedUser.role === ROLES.ADMIN
    )
  }

  get canHaveProjects(): boolean {
    return this.user.role === ROLES.USER
  }

  updateUser(user: User) {
    this.$store
      .dispatch('updateUser', {
        id: this.user.id,
        user: user,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.userDetailsEl.editing = false
        }
      })
  }

  deleteAccount() {
    if (confirm('Êtes-vous certain de vouloir supprimer cet utilisateur ?')) {
      this.$store.dispatch('deleteUser', this.user.id).then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.$router.back()
        }
      })
    }
  }

  disableAccount() {
    this.$store.dispatch('disableUser', this.user.id).then(json => {
      this.$store.dispatch('toaster/toast', json)
    })
  }

  enableAccount() {
    this.$store.dispatch('enableUser', this.user.id).then(json => {
      this.$store.dispatch('toaster/toast', json)
    })
  }
}
